/* Mixins */
.page-body[data-v-0fd5e258] {
  background: #fff;
  padding: 20px;
}
.header-wrapper[data-v-0fd5e258] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
.header-wrapper .header-left-wrapper[data-v-0fd5e258] {
  width: 200px;
  margin-right: 20px;
}
.header-wrapper .header-middle-wrapper[data-v-0fd5e258] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header-wrapper .header-middle-wrapper div[data-v-0fd5e258] {
  margin-right: 20px;
}
.header-wrapper .header-right-wrapper[data-v-0fd5e258] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 200px;
}
.header-wrapper .header-right-wrapper div[data-v-0fd5e258]:first-child {
  margin-right: 30px;
}
.shift-option[data-v-0fd5e258] {
  height: 30px;
  line-height: 20px;
  padding: 5px 0;
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
}
.shift-option[data-v-0fd5e258]:hover {
  cursor: pointer;
  color: #fff;
  background: gray;
}